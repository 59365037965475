import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageWhatTowbookIsNot = () => (
  <Layout>
    <PageTitle title="What Towbook is Not"></PageTitle>
    <ContentBlock className="rich-text">
      <p>
        Towbook is the software platform for the towing and recovery industry.
      </p>
      <p>
        We help companies of all shape and size manage their business, including
        dispatching, impounds, reporting and invoicing. We also provide the path
        to connect those businesses who need work done to those able to perform
        the service.
      </p>
      <p>
        Towbook is not a motor club or insurance company. We don&apos;t get
        involved in helping companies build a network of service providers. We
        also never get involved in the business of negotiating rates,
        establishing service levels, or managing service provider performance.
      </p>
      <p>
        We&apos;re also not a call center, or dispatch service. We do not field
        phone calls, manage communication with customers at the side of the road
        or determine services required and coverage levels.
      </p>
      <p>Finally, Towbook is not in the business of paying for services.</p>
      <p>
        What we do, and we are the very best at it, is help service providers
        manage their business. We stick to the business of providing software,
        period.
      </p>
    </ContentBlock>
  </Layout>
)

export default PageWhatTowbookIsNot
